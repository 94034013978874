//import { Link } from 'gatsby'
import React from 'react'
import LogoSmallSVG from '../../assets/logosmall.svg'
import './style.scss'

interface Props {
  year: number
}

const Footer: React.FC<Props> = ({ year }: Props) => (
  <div className="footer p-4">
    <div className="container text-center">
      <p className="m-0 mb-2">&copy; {year} Sodality Ventures</p>
      <a href="/">
        <img src={LogoSmallSVG} className="footerLogo" id="logo" />
      </a>
    </div>
  </div>
)

export default Footer
