import React, { useEffect } from 'react'
import Logo from '../../assets/svLogo.png'
import LogoSVG from '../../assets/svLogo.svg'

import './style.scss'

interface Props {
  location: Location
}

const isBrowser = () => typeof window !== 'undefined'

const Navibar: React.FC<Props> = ({}: Props) => {
  useEffect(() => {
    const logo = document.getElementById('logo')
    if (logo) {
      logo.addEventListener('error', function (e) {
        if (e && e.target) e.target.src = { Logo }
      })
    }
  })

  return (
    <nav className="navbar navbar-expand-lg navbar-light sticky-top">
      <div className="header-background"></div>
      <a className="navbar-brand p-0 m-0" href="/">
        <img src={LogoSVG} className="navLogo" id="logo" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul className="navbar-nav">
          {/*<li
            className={
              location.pathname === '/' ? 'nav-item active' : 'nav-item'
            }
          >
            <a href="/" className="nav-link">
              Home
            </a>
          </li>*/}
          <li
            className={
              isBrowser() && location.pathname === '/learn-more/'
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <a href="/learn-more/" className="nav-link">
              About Us
            </a>
          </li>
          <li
            className={
              isBrowser() && location.pathname === '/is-VC-for-me/'
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <a href="/is-VC-for-me/" className="nav-link">
              Is VC for Me?
            </a>
          </li>
          <li
            className={
              isBrowser() && location.pathname === '/FAQ/'
                ? 'nav-item active'
                : 'nav-item'
            }
          >
            <a href="/FAQ/" className="nav-link">
              FAQ
            </a>
          </li>
          <li className="nav-item">
            <a href="https://blog.sodalityventures.com/" className="nav-link">
              Blog
            </a>
          </li>
        </ul>
        <form className="form-inline my-2 my-lg-0">
          <ul className="navbar-nav bd-navbar-nav flex-row">
            <li
              className={
                isBrowser() && location.pathname === '/work-with-us/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <a href="/work-with-us/" className="nav-link">
                <button className="btn btn-primary my-2 my-sm-0">Apply</button>
              </a>
            </li>
          </ul>
        </form>
      </div>
    </nav>
  )
}

export default Navibar

/*

    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/">
        <img src={LogoSVG} className="navLogo" id="logo" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarToggler">
        <div className="d-flex">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="nav-item active">
              <a className="nav-link" href="#">
                Home <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link disabled"
                href="#"
                tabIndex={-1}
                aria-disabled="true"
              >
                Disabled
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
*/
/*
<nav className="navbar navbar-expand navbar-dark flex-column flex-md-row bg-primary">
      <div className="container">
        <Link to="/">
          <img src={Logo} className="logo" />
        </Link>
        <div className="navbar-nav-scroll">
          <ul className="navbar-nav bd-navbar-nav flex-row">
            <li
              className={
                location.pathname === '/work-with-us/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <Link to="/work-with-us/" className="nav-link">
                Work With Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
      </div>
    </nav>
    */
